<template>
  <div class="footer">
    <!-- <div class="footer__header">
      <h2>Poznaj zespół Krotoski EV</h2>
    </div> -->

    <div class="footer__content">
      <div class="footer__content-workers">
        <div class="footer__content-workers--header">
          <h2>Poznaj nasz zespół</h2>

          <div class="footer__content-workers--localization">
            <!-- <div v-if="localization == ''">
              <p>
                Wybierz salon:
              </p>
            </div> -->
            <div v-if="localization != ''">
              <h2>{{ localization }}</h2>
              <p class="click" @click="changeLocalization('')">
                <img src="../assets/images/arrow-left.svg" alt="">
                Wróć
              </p>
            </div>
          </div>
          <!-- <div class="localizationButton" :class="{'right' : localization == 'Nadarzyn'}">
            <div class="localizationButton__elem" @click="changeLocalization('Swadzim')" :class="{'active' : localization == 'Swadzim'}">
              Poznań – Swadzim
            </div>
            <div class="localizationButton__elem" @click="changeLocalization('Nadarzyn')" :class="{'active' : localization == 'Nadarzyn'}">
              Warszawa – Nadarzyn
            </div>
          </div> -->
        </div>

        <div class="footer__content-localization" v-if="localization == ''">
          <!-- <div class="footer__content-localization--elem" @click="changeLocalization('Swadzim')">
            <div>
              <img src="../assets/images/swadzim_new.jpg" alt="Swadzim">
              <h4>
                Poznań - Swadzim
              </h4>
              <p>
                ul. Ogrodowa 11 <br>
                62-080 Swadzim k/Poznania <br>
                Tel.: <a href="tel:+48 61 635 06 80">+48 61 635 06 80</a>
              </p>
            </div>
          </div> -->
          <div class="footer__content-localization--elem" @click="changeLocalization('Nadarzyn')">
            <div>
              <img src="../assets/images/nadarzyn.jpg" alt="Nadarzyn">
              <h4>
                Warszawa - Nadarzyn
              </h4>
              <p>
                al. Katowicka 83 <br>
                05-830 Nadarzyn k/Warszawy <br>
                Tel.: <a href="tel:+48 661 992 451">+48 661 992 451</a>
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <div>
            <div
            v-for="(content, department) in filterWorkers(localization)"
            :key="department"
            :value="department">
              <div class="department__header">
                <h3>Dział {{ department }}</h3>
              </div>
              <div class="footer__content-workers--flex">
                <div 
                v-for="(worker, id) in content" 
                :key="id"
                class="footer__content-workers--elem">
                  <div class="mg-bottom" v-if="showAll ? true : id < 3">
                    <img
                    :src="require('@/assets/images/workers/' + worker.url)"
                    :alt="worker.name" />
                    <p>
                      <strong>{{ worker.name }}</strong>
                    </p>
                    <p>{{ worker.position }}</p>
                    <br />
                    <p>
                      <a :href="'tel:' + worker.telephone"
                        >tel.: {{ worker.telephone }}</a
                      >
                    </p>
                    <p>
                      <a :href="'mailto:' + worker.mail">{{ worker.mail }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="showAll" @click="showAllWorkers()">
            <div class="showAll__elem">
              <p v-if="!showAll">Pokaż więcej</p>
              <p v-else>Pokaż mniej</p>
              <img src="../assets/images/arrow-white.svg" alt="" :class="{'active': showAll}" >
            </div>
          </div>
        </div>
      </div>

      <div class="footer__content-info">
        <div class="footer__content-info--container">
          <div>
            <strong>DANE FIRMY:</strong> <br />
            Krotoski&nbsp;Electromobility&nbsp;Sp.&nbsp;z&nbsp;o.o. <br />
            Niciarniana 51/53, Łódź <br />
            NIP: 5242758915
          </div>
          <!-- <div class="footer__content-info--mg">
            <strong>Salon MAXUS</strong> <br />
            ul. Ogrodowa 11 <br />
            62-080 Swadzim k/Poznania <br />
            <a href="tel:+48 61 635 06 80">Tel.: +48 61 635 06 80</a>
          </div>
          <div>
            <strong>Salon MAXUS</strong> <br />
            al. Katowicka 83 <br />
            05-830 Nadarzyn k/Warszawy <br />
            <a href="tel:+48 661 992 451">Tel.: +48 661 992 451</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localization: '',
      showAll: false,
      showDepartment: 'handlowy',
      workers: {
        'Nadarzyn': {
          'handlowy' : [
            {
              url: "maciej-makowski.png",
              name: "Maciej Makowski",
              position: "Specjalista ds. Sprzedaży",
              telephone: "785 072 575",
              mail: "maciej.makowski@krotoski.com",
            },
            {
              url: "beniamin-swiglinski.png",
              name: "Beniamin Świgliński",
              position: "Specjalista ds. Sprzedaży",
              telephone: "785 071 860",
              mail: "beniamin.swiglinski@krotoski.com",
            },
            {
              url: "dominika-kowalczyk.png",
              name: "Dominika Kowalczyk",
              position: "Asystentka Salonu",
              telephone: "665 551 893",
              mail: "dominika.kowalczyk@krotoski.com",
            },
            {
              url: "michal-dabkowski.png",
              name: "Michał Dąbkowski",
              position: "Asystent Działu Sprzedaży",
              telephone: "665 551 893",
              mail: "michal.dabkowski@krotoski.com",
            },
            {
              url: "waldemar-rudzik.png",
              name: "Waldemar Rudzik",
              position: "Kierownik Działu Sprzedaży",
              telephone: "661 992 451",
              mail: "waldemar.rudzik@krotoski.com",
            }
          ],
          'serwisowy': [
            {
              url: "pawel-radecki.png",
              name: "Paweł Radecki",
              position: "Doradca Serwisowy",
              telephone: "665 551 348",
              mail: "pawel.radecki@krotoski.com",
            },
            {
              url: "rafal-dudzinski.png",
              name: "Rafał Dudziński",
              position: "Kierownik Serwisu",
              telephone: "665 551 433",
              mail: "rafal.dudzinski@krotoski.com",
            },                                 
          ]
        },
        // 'Swadzim': {
        //   'handlowy': [
        //     {
        //       url: "worker-placecholder.png",
        //       name: "Tomasz Koźlik",
        //       position: "Kierownik Działu Sprzedaży",
        //       telephone: "728 496 145",
        //       mail: "tomasz.kozlik@krotoski.com",
        //     },
        //     {
        //       url: "worker-placecholder.png",
        //       name: "Marcin Nowak",
        //       position: "Specjalista ds. Sprzedaży",
        //       telephone: "785 072 763",
        //       mail: "nowak.marcin@krotoski.com",
        //     }
        //   ],
        //   'serwisowy': [
        //     {
        //       url: "marta_hoffmann.png",
        //       name: "Marta Hoffmann",
        //       position: "Koordynator Działu Serwisu Blacharsko-Lakierniczego",
        //       telephone: "661 990 260",
        //       mail: "marta.hoffmann@krotoski.com",
        //     },                                                         
        //     {
        //       url: "worker-placecholder.png",
        //       name: "Paweł Konys",
        //       position: "Doradca Serwisu",
        //       telephone: "+48 661 991 737",
        //       mail: "pawel.konys@krotoski.com",
        //     },
        //     {
        //       url: "worker-placecholder.png",
        //       name: "Ewa Sobczyk",
        //       position: "Doradca Serwisu",
        //       telephone: "+48 661 990 261",
        //       mail: "ewa.sobczyk@krotoski.com",
        //     }
        //   ]
        // },
      },
    };
  },
  methods: {
    filterWorkers: function (name) {
      if(this.showAll){
        console.log("pokaz wszystkie");
        return this.workers[name];
      }else{
        console.log("Nie wszystkie");
        return Object.keys(this.workers[name])
                .filter((key) => key.includes(this.showDepartment))
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                      [key]: this.workers[name][key]
                    });
              }, {});
      }
    },
    showAllWorkers() {
      this.showAll = !this.showAll;
    },
    changeLocalization(localizationName) {
      this.showAll = false;
      this.localization = localizationName;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  font-family: "LATO";
  overflow: hidden;
  margin-top: 100px;

  &__header {
    text-align: center;
    margin: 0 30px 50px 30px;
  }

  &__content {
    position: relative;
    // margin-top: 60px;
    padding: 0px 85px;
    @media (max-width: 1440px) {
      padding: 0px 50px;
    }
    @media (max-width: 960px) {
      padding: 0px 30px;
    }
    @media (max-width: 400px) {
      padding: 0px;
    }

    &:after {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 280px;
      bottom: 0px;
      content: "";
      height: 100%;
      width: 100%;
      background-color: #848080;
      z-index: 0;
    }

    &-localization{
      display: flex;
      justify-content: center;

      @media(max-width: 768px){
        flex-wrap: wrap;
      }

      &--elem{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #FFFFFF;
        border-radius: 10px;
        color: #ffffff;
        text-align: center;
        margin: 0 8px 20px 8px;
        max-width: 536px;
        width: 100%;
        min-height: 477px;
        cursor: pointer;
        transition: border 0.3s ease-in-out;
        padding: 20px;

        @media(max-width: 960px){
          min-height: auto;
        }

        &:hover{
          border: 10px solid #ffffff;
        }

        h4{
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 12px;

          @media(max-width: 1440px){
            font-size: 30px;
          }
          @media(max-width: 960px){
            font-size: 24px;
          }
        }
        p{
          font-size: 24px;

          @media(max-width: 1440px){
            font-size: 20px;
          }
          @media(max-width: 960px){
            font-size: 18px;
          }
          @media(max-width: 420px){
            font-size: 16px;
          }
        }
        img{
          border-radius: 8px;
          margin-bottom: 20px;
        }
      }
    }

    &-workers {
      position: relative;
      z-index: 1;
      background-color: #3abeee;
      border-radius: 20px 20px 0px 0px;
      padding: 0 55px 90px 55px;
      margin-bottom: -55px;
      max-height: 100%;
      transition: all 0.7s;
      @media (max-width: 960px) {
        padding: 0 20px 80px 20px;
      }

      &--flex {
        position: relative;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
      }

      &--header{
        h2{
          font-size: 48px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          padding: 60px 0;

          @media(max-width: 1440px){
            font-size: 38px;
            padding: 40px 0;
          }
          @media(max-width: 960px){
            font-size: 24px;
            padding: 30px 0;
          }
        }
      }
      
      &--localization{
        color: #ffffff;
        margin: 0 0 60px 0;
        text-align: center;

        h2{
          padding: 0 0 16px 0;
        }
        p{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: bold;

          img{
            margin-right: 5px;
          }
        }

        .click{
          cursor: pointer;
        }
      }

      &--elem {
        width: 100%;
        max-width: calc(100% / 3);
        text-align: center;
        color: #ffffff;

        @media(max-width: 1150px){
          max-width: calc(100% / 2);
        }
        @media(max-width: 700px){
          max-width: 100%;
        }

        .mg-bottom{
          margin: 0px 0 50px 0;
        }

        p {
          font-size: 24px;
          font-weight: 200;
          @media (max-width: 1440px) {
            font-size: 20px;
          }
          @media (max-width: 400px) {
            font-size: 18px;
          }
        }
      }
    }

    &-info {
      position: relative;
      z-index: 2;
      background-color: #0099c0;
      border-radius: 20px 20px 0px 0px;
      padding: 76px 55px 60px 55px;
      @media (max-width: 1440px) {
        padding: 50px 50px 40px 50px;
      }
      @media (max-width: 960px) {
        padding: 50px 30px 40px 30px;
      }

      &--container {
        display: flex;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        max-width: 1088px;
        margin: 0 auto;
        font-size: 24px;
        @media (max-width: 1440px) {
          font-size: 20px;
        }
        @media (max-width: 960px) {
          font-size: 18px;
          display: block;
          text-align: center;
        }
      }

      &--mg {
        margin-right: 100px;
        @media (max-width: 960px) {
          margin: 0 0 50px 0;
        }
      }
    }
  }
}

.localizationButton{
  position: relative;
  display: flex;
  max-width: 600px;
  background-color: #0099C0;
  border-radius: 20px;
  margin:0 auto 80px auto;

  @media(max-width: 550px){
    max-width: 280px;
    margin:0 auto 50px auto;
  }

  &::before{
    display: block;
    content: '';
    position: absolute;
    top:0px;
    left:0px;
    right: auto;
    bottom: 0px;
    max-width: 50%;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
    border-radius: 20px;

  }

  &.right{
    &::before{
      right: 0px;
      left: auto;
    }
  }

  &__elem{
    position: relative;
    max-width: 50%;
    width: 100%;
    text-align: center;
    padding:20px 15px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    z-index: 3;
    cursor: pointer;
    transition: color 0.6s;

    @media(max-width: 650px){
      font-size: 20px;
      padding:15px 13px;
    }

    &.active{
      color: #0099C0;
      cursor: none;
    }
  }
}

.department{
  &__header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:100px 0;

    @media(max-width: 700px){
      margin:50px 0;
    }

    &::before{
      content: '';
      display: block;
      position: absolute;
      background-color: #ffffff;
      width: 100%;
      height: 1px;
    }

    h3{
      position: relative;
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      background-color: #3ABEEE;
      padding:0 30px;

      @media(max-width: 700px){
        font-size: 24px;
        padding:0 15px;
      }
    }
  }
}

.showAll{
  margin: 60px 0px;
  text-align: center;

  &__elem{
    position: relative;
    background-color: #FFFFFF;
    z-index: 3;
    padding: 35px 39px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
  
    p{
      color: #0099C0;
      font-size: 24px;
      font-weight: bold;
      margin-right: 16px;
    }

    img{
      transition: all 0.6s;
      &.active{
        transform: rotate(180deg);
      }
    }
  }
}
</style>
