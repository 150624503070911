<template>
  <div id="app">
    <Navigation/>
    <Baner/>
    <BlueBox/>
    <Models/>
    <Form/>
    <Footer/>
  </div>
</template>

<script>
import Navigation from './components/Nav.vue'
import Baner from './components/Baner.vue'
import BlueBox from './components/BlueBox.vue'
import Models from './components/Models.vue'
import Form from './components/Form.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Baner,
    BlueBox,
    Models,
    Form,
    Footer
  }
}
</script>

<style lang="scss">
@import './assets/fonts/stylesheet.css';
#app{
  position: relative;
}
body, html {
  font-family: 'ubuntu';
  color:#000000;
  background-color: #ffffff;
  max-width: 1920px;
  min-height: 100vh;
  margin:0 auto;
  padding:0;
  font-size:16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
*, :after, :before {
    box-sizing: border-box;
}
h2{
  font-size: 48px;
  @media(max-width: 1440px){
    font-size: 40px;
  }
  @media(max-width: 1200px){
    font-size: 32px;
  }
  @media(max-width: 960px){
    font-size: 26px;
  }
  @media(max-width: 500px){
    font-size: 22px;
  }
}
h3{
  font-size: 40px;
  @media(max-width: 1440px){
    font-size: 32px;
  }
  @media(max-width: 1200px){
    font-size: 26px;
  }
  @media(max-width: 960px){
    font-size: 24px;
  }
  @media(max-width: 500px){
    font-size: 20px;
  }
    @media(max-width: 450px){
    font-size: 18px;
  }
}
h1,h2,h3,h4,h5,p{
  margin:0px;
}
a{
  color: inherit;
  text-decoration: none;
}
img{
  max-width: 100%;
}
label{
  cursor: inherit;
}
.text-center{
  text-align: center;
}

.VueCarousel-pagination--bottom-overlay{
  bottom: 30px!important;

  @media(max-width: 960px){
    bottom: 10px!important;
  }
}
.VueCarousel-dot{
  display: inline-flex!important;
  align-items: center;
  justify-content: center;
  margin-top: 0px!important;
  outline: 0px;
  @media(max-width: 960px){
    width: 16px!important;
    height: 16px!important;
  }
  &:focus{
    outline: 0px!important;
  }
  &:after{
    display: block;
    content:'';
    width: 14px;
    height: 14px;
    background: #ffffff;
    border-radius: 100%;

    @media(max-width: 960px){
      width: 10px;
      height: 10px;
    }
  }
  &--active:after{
    background: #3ABEEE;
  }
  &-container{
    background-color: rgba(0,0,0,0.5);
    border-radius: 15px;
  }
}

.form{
  &__title{
    text-align: center;
    margin: 0 50px 50px 50px;
    font-weight: bold;

    h3 br{
      @media(max-width: 960px){
        display: none;
      }
    }
  }
  
  &__wrapper{
    font-family: "Lato";
    margin:50px 7.292vw 100px 7.292vw;
    @media(max-width: 960px){
      margin:30px 30px 70px 30px;
    }
  }
  
  &__inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width:960px){
        margin: 0 0%;
    }
  }
  
  &__input {
    max-width: calc((100% / 3) - 16px);
    margin-bottom: 30px;
    width: 100%;
  
    // @media (max-width: 1440px) {
    //   max-width: calc((100% / 2) - 16px);
    // }
    @media (max-width: 960px) {
      max-width: 100%;
    }
  
    input,select{
      margin-top:15px;
    }
    &-header{
      font-size: 24px;
      @media(max-width:1440px){
        font-size: 20px;
      }
      @media(max-width:960px){
        font-size: 18px;
      }
    }
  }
  
  &__accepts{
    // margin-top: 30px;
  }
  
  .show__more{
    cursor: pointer;
    color:#F60538;
  }
  .radio__wrap{
    display: flex;
    align-items: center;
    font-size:20px;
    font-weight: 500;
  }
  .radio__wrap input{
    margin-right:15px;
  }
  
  &__input input,&__input select {
    font-family: "Lato";
    width: 100%;
    color: #000000;
    font-size: 24px;
    font-weight: 200;
    padding:14px 30px;
    cursor: pointer;
    border-radius: 20px;
    border:1px solid #BFBFBF;
    @media(max-width:1440px){
      font-size: 20px;
    }

    &:focus{
      outline: 0px;
    }
    &::placeholder{
      font-family: "Lato";
      font-size: 24px;
      color:#848080;
      font-weight: 200;
      @media(max-width:1440px){
        font-size: 20px;
      }
      @media(max-width:960px){
        font-size: 18px;
      }
    }
  }
  
  .checkboxes {
    &__content{
      position: relative;
      display: flex;
      width: 100%;
      background-color: #848080;
      border-radius: 20px;
      margin-top: 15px;
      @media(max-width:960px){
        margin-top:10px;
      }
  
      &::before{
        position: absolute;
        z-index: 1;
        top: 0px;
        bottom: 0px;
        content:'';
        width: 50%;
        background-color: #3ABEEE;
        border-radius: 20px;
        transition: left 0.2s;
      }
      &.Osobowy::before{
        left: 0px;
      }    
      &.Dostawczy::before{
        left: 50%;
      }
  
      &-elem{
        position: relative;
        z-index: 2;
        font-family: "Lato";
        text-align: center;
        width: 50%;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
        padding:15px 5px;
        cursor: pointer;
        @media(max-width:1440px){
          font-size: 20px;
        }
        @media(max-width:960px){
          font-size: 18px;
        }
      }
    }
  
    & input[type=checkbox] {
      height: 0px;
      width: 0px;
      visibility: hidden;
      display: none;
    }
  }
  
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #848080;
    color: #ffffff;
    border-radius: 20px;
    font-size: 24px;
    max-width: 260px;
    width: 100%;
    padding: 36px 15px;
    cursor: pointer;
    margin-top:50px;
    transition: background-color 0.4s;

    @media(max-width:960px){
      margin-top:30px;
    }

    &:hover{
      background-color: #000000;
    }

    img{
      margin-left:20px;
    }
  }
  
  /* Main Classes */
  .contact-form__rights-checkbox[type="checkbox"]{
    &:before {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid #848080;
      border-radius: 10px;
      content: "";
      background: #fff;
    }
    
    &:after {
      position: relative;
      display: block;
      left: 0px;
      top: -40px;
      width: 40px;
      height: 40px;
      border-width: 0px;
      border-style: solid;
      /* border-color: #b3b3b3 #dcddde #dcddde #b3b3b3; */
      border-radius: 10px;
      content: "";
      background-repeat: no-repeat;
      background-position: center;
    }
    
    &:checked:after {
      background-color: #000;
    }
    
    &:disabled:after {
      -webkit-filter: opacity(0.4);
    }
  }
}

.marketing-regulations {
  position: relative;
  margin-bottom: 1rem;
  &.flex{
    display: flex;
  }
}

.text-error {
  color: red;
  font-size: 16px;
}

.contact-form__label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 200;
  color: #848080;

  @media (min-width: 1025px) {
    text-align: left;
  }
  
  &--checkbox {
    padding-left: 60px;
  }
}

.contact-form__rights{
  &-checkbox {
    position: absolute;
    left: 0;
    width: 0px;
    height: 0px;
    border: 1px solid #fff !important;
    background-color: #fff;

    @media (min-width: 1025px) {
      left: 0;
    }
  }

  &-text {
    font-size: 14px;
    font-weight: 200;
    color: #848080;
  }
}

.successform{
	text-align: center;
	margin:40px 10px;
}
</style>
