<template>
  <div id="form">
    <div class="form__title" v-if="!message">
      <h3>Zapisz się na jazdę testową. <br> Wypełnij formularz - nasi doradcy skontaktują się z Tobą</h3>
    </div>
    <div class="form__wrapper">
      <form class="form" v-if="!message">
        <div class="form__inputs">
          <!-- <div class="form__input checkboxes">
            <label class="form__input-header">Wybierz</label>
            <div class="checkboxes__content" :class="formData.type">
              <label class="checkboxes__content-elem">
                <input type="checkbox" 
                value="Osobowy" 
                v-model="formData.type" 
                true-value="Osobowy" 
                false-value=""
                :disabled="formData.type == 'Osobowy'">
                Osobowy
              </label>
              <label class="checkboxes__content-elem">
                <input type="checkbox" 
                value="Dostawczy" 
                v-model="formData.type" 
                true-value="Dostawczy" 
                false-value=""
                :disabled="formData.type == 'Dostawczy'">
                  Dostawczy
              </label>
            </div>
            <span class="text-error" v-if="errors.type && errors.type.errors">
              {{ errors.type.message }}
            </span>
          </div> -->
          <div class="form__input">
            <label class="form__input-header">Imię i nazwisko</label>
            <input type="text" placeholder="-wpisz-" v-model="formData.name">
            <span class="text-error" v-if="errors.name && errors.name.errors">
              {{ errors.name.message }}
            </span>
          </div>
          <div class="form__input">
            <label class="form__input-header">Numer telefonu</label>
            <input type="text" placeholder="-wpisz-" v-model="formData.phone_number">
            <span class="text-error" v-if="errors.phone_number && errors.phone_number.errors">
              {{ errors.phone_number.message }}
            </span>
          </div>
          <div class="form__input">
            <label class="form__input-header">Adres e-mail</label>
            <input type="text" placeholder="-wpisz-" v-model="formData.email">
            <span class="text-error" v-if="errors.email && errors.email.errors">
              {{ errors.email.message }}
            </span>
          </div>
          <!-- <div class="form__input">
            <label class="form__input-header">Lokalizacja</label>
            <select v-model="formData.localization">
              <option value="" disabled selected>-wybierz-</option>
              <option value="Swadzim k/Poznania">Swadzim k/Poznania</option>
              <option value="Nadarzyn k/Warszawy">Nadarzyn k/Warszawy</option>
            </select>
            <span class="text-error" v-if="errors.localization && errors.localization.errors">
              {{ errors.localization.message }}
            </span>
          </div> -->
        </div>
        <div class="form__accepts">
          <div class="marketing-regulations flex">
            <label class="contact-form__label">
              <p class="contact-form__rights-text">
                Administratorem danych przekazywanych za pomocą powyższego formularza jest Krotoski ElectroMobility Sp. z o.o.              
              </p>
              <strong>
                <p class="show__more" v-on:click="ToggleShowmore()" v-html="ShowMoreText"></p>
              </strong>
              <transition name="fade">
                <div v-if="showmore">
                  <p class="contact-form__rights-text">
                    1. Kto jest administratorem danych osobowych?
                    <br><br>
                    Administratorem, czyli podmiotem decydującym o tym w jakim sposób będą wykorzystywane Twoje dane osobowe jest Krotoski ElectroMobility Sp. z o.o. z siedzibą w Łodzi (92-320), ul. Niciarniana 51/53, wpis do Rejestru Przedsiębiorców Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX Wydział Krajowego Rejestru Sądowego pod numerem KRS 0000463723, NIP 5242758915, REGON 146647671, kapitał zakładowy 2 000 000 zł.
                    <br><br>
                    Jeżeli chcesz wiedzieć więcej o przetwarzaniu Twoich danych osobowych skontaktuj się z Inspektorem Ochrony Danych: <br>
                    • napisz na adres mailowy: dane.osobowe@krotoski.com lub zadzwoń tel. +48 785 012 459.
                    <br><br>
                    2. Jakie dane przetwarzamy, w jakich celach, na jakiej podstawie prawnej oraz jak długo je przechowujemy?
                    <br><br>
                    Zakres danych: Dane podane w formularzu  <br>
                    Cel przetwarzania: Przetwarzamy Twoje dane podane w formularzu w celu przygotowania i przedstawienia Ci oferty naszych usług w tym zakresie, dopasowanej do Twoich indywidualnych potrzeb, kontaktowania się z Tobą w jej sprawie oraz udzielenia odpowiedzi na zadane przez Ciebie pytanie. <br>
                    Podstawa prawna: Niezbędność do podjęcia działań na Twoje żądanie przed zawarciem umowy, polegających na przedstawieniu Ci oferty naszych usług (art. 6 ust. 1 lit. b RODO).
                    <br><br>
                    Podstawą prawną przetwarzania w tym przypadku jest również nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO), polegający na: <br>
                    • prowadzeniu marketingu naszych usług, w tym poprzez przygotowanie i przedstawienie oferty; <br>
                    • udzieleniu odpowiedzi na zadane przez Ciebie pytanie; <br>
                    • budowaniu pozytywnych relacji z Tobą jako potencjalnym klientem.
                    <br><br>
                    Okres przechowywania: Twoje dane przechowujemy przez okres niezbędny do przygotowania i przedstawienia Ci oferty oraz kontaktowania się z Tobą w jej sprawie, nie dłużej niż  do czasu wyrażenia przez Ciebie sprzeciwu wobec dalszego przetwarzania danych w celach marketingowych.
                    Podanie danych jest dobrowolne. Bez ich podania nie otrzymasz oferty naszych usług.
                    <br><br>
                    3. Komu przekazujemy Twoje dane:
                    <br><br>
                    Twoje dane przekazujemy dostawcom usług, z których korzystamy przy prowadzeniu strony internetowej, tj. usług w zakresie hostingu oraz Agencji Reklamowej, która wspiera Nas w działaniach marketingowych.
                    Nie przekazujemy danych poza EOG.
                    <br><br>
                    4. Jakie masz prawa związane z przetwarzaniem Twoich danych?
                    <br><br>
                    Masz prawo dostępu do swoich danych osobowych, ich sprostowania, żądania ich usunięcia, żądania ograniczenia ich przetwarzania oraz wniesienia sprzeciwu co do przetwarzania Twoich danych osobowych.
                    <br><br>
                    5. Czy po przekazaniu danych możesz sprzeciwić się ich przetwarzaniu?
                    <br><br>
                    Tak. Możesz to zrobić w dwóch sytuacjach, gdy przetwarzamy Twoje dane: <br>
                    • na potrzeby marketingu bezpośredniego; takiego sprzeciwu nie musisz uzasadniać; <br>
                    • na podstawie innych naszych prawnie uzasadnionych interesów; taki sprzeciw wymaga uzasadnienia Twoją szczególną sytuacją.
                    <br><br>
                    6. Co możesz zrobić, jeżeli uważasz, że Twoje dane są przetwarzanie niezgodnie z prawem?
                    <br><br>
                    Skontaktuj się z nami - udzielimy odpowiedzi na Twoje pytania lub odpowiemy na zgłoszone zastrzeżenia.
                    Możesz również złożyć skargę do organu nadzorczego. W Polsce jest nim Prezes Urzędu Ochrony Danych Osobowych.
                    <br><br>
                    7. W jaki sposób dbamy o bezpieczeństwo Twoich danych?
                    <br><br>
                    Dokładamy wszelkich starań, aby zapewnić Twoim danym bezpieczeństwo. Nasza strona internetowa używa szyfrowanej transmisji danych (SSL) podczas przesyłania danych podanych przez Ciebie w formularzach. Zapewnia to ochronę identyfikujących Cię danych oraz znacząco utrudnia ich przechwycenie przez nieupoważnione osoby lub systemy.
                    <br><br>
                    8. Twoje dane nie będą wykorzystywane przez nas do zautomatyzowanego podejmowania decyzji, w tym profilowania.
                  </p>
                </div>
              </transition>
            </label>
          </div>
          <!-- <div class="marketing-regulations">
            <label class="contact-form__label">
              Wyrażam dodatkową zgodę na przekazywanie mi informacji marketingowych w wybrany przeze mnie sposób:
            </label>
          </div>
          <div class="marketing-regulations flex">
            <input id="accept1" name="accept1" type="checkbox" class="contact-form__rights-checkbox" required v-model="accepts.accept1.value" />
            <label for="accept1" class="contact-form__label contact-form__label--checkbox">
              <p class="contact-form__rights-text">
                <strong>Kontakt tekstowy (mail):</strong> Chcę otrzymywać mailem informacje marketingowe.
              </p>
              <strong>
                <p class="show__more" v-if="!accepts.accept1.showMore" v-on:click="accepts.accept1.showMore = true">Zobacz więcej</p>
                <p class="show__more" v-else v-on:click="accepts.accept1.showMore = false">Zwiń</p>
              </strong>
              <transition name="fade">
                <div v-if="accepts.accept1.showMore">
                  <p class="contact-form__rights-text">
                    Zaznaczając to pole zgadzasz się na przesyłanie Ci mailem informacji marketingowych dotyczących produktów i usług oferowanych przez Krotoski ElectroMobility Sp. z o.o. w tym informacji o nowościach w naszych produktach i usługach lub o planowanych przedsięwzięciach.
                  </p>
                </div>
              </transition>
            </label>
          </div>
          <div class="marketing-regulations flex">
            <input id="accept2" name="accept2" type="checkbox" class="contact-form__rights-checkbox" required v-model="accepts.accept2.value" />
            <label for="accept2" class="contact-form__label contact-form__label--checkbox">
              <p class="contact-form__rights-text">
                <strong>Kontakt telefoniczny:</strong> Chcę, by dzwoniono do mnie na podany numer telefonu w celu przekazywania mi informacji marketingowych.
              </p>
              <strong>
                <p class="show__more" v-if="!accepts.accept2.showMore" v-on:click="accepts.accept2.showMore = true">Zobacz więcej</p>
                <p class="show__more" v-else v-on:click="accepts.accept2.showMore = false">Zwiń</p>
              </strong>
              <transition name="fade">
                <div v-if="accepts.accept2.showMore">
                  <p class="contact-form__rights-text">
                    Zaznaczając to pole zgadzasz się na kontakt telefoniczny z naszej strony na podany numer telefonu z celu przekazywania Ci informacji marketingowych dotyczących produktów i usług oferowanych przez Krotoski ElectroMobility Sp. z o.o. w tym informacji o nowościach w naszych produktach i usługach.
                  </p>
                </div>
              </transition>
            </label>
          </div> -->
        </div>
        <div class="text-center">
          <div class="form__btn send" @click="sendForm()">
            Wyślij
            <img src="../assets/images/arrow.svg" alt="">
          </div>
        </div>
      </form>

      <div class="form__title" v-if="message">
            <h2 v-html="msg"></h2>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      message: false,
      msg: "",
      showmore: false,
      ShowMoreText: "Zobacz więcej",
      formData: {
        name: "",
        email: "",
        phone_number: "",
        // localization: ""
        // type: "Osobowy",
        // accept1: "",
        // accept2: "",
      },
      // accepts: {
      //   accept1: {
      //     showMore: false,
      //     value: false,
      //     error: false,
      //   },
      //   accept2: {
      //     showMore: false,
      //     value: false,
      //     error: false,
      //   }
      // },
      errors: {},
      send: false
    }
  },
  validate: {
    messages: {
      name: {
        require: "To pole jest wymagane!",
      },
      email: {
        requireGroup: "Numer telefonu lub e-mail jest wymagany!",
        email: "Adres e-mail jest nie poprawny!",
      },
      phone_number: {
        requireGroup: "Numer telefonu lub e-mail jest wymagany!",
        phone: "Numer telefonu jest nie poprawny!",
      }
    },
    rules: {
      name: {
        require: true,
      },
      email: {
        requireGroup: "contact",
        email: true,
      },
      phone_number: {
        requireGroup: "contact",
        phone: true,
      }
    },
  },
  methods: {
    ToggleShowmore(field) {
      this.showmore = !this.showmore;
      if (this.showmore) {
        this.ShowMoreText = "Zwiń";
      } else {
        this.ShowMoreText = "Zobacz więcej";
      }
    },
    sendForm: function sendForm() {
      var that = this;
      this.errors = {};
      this.errors = this.$vaLittle.check(this.formData);

      if (!this.errors.errors && !this.send) {
        this.send = true;
        
        // this.formData.accept1 = this.accepts.accept1.value;
        // this.formData.accept2 = this.accepts.accept2.value;
        grecaptcha.ready(async () => {
          grecaptcha.execute('6LeQMlspAAAAACXaNniwx_yIg9NfCkRruTb01nXX', { action: 'submit' }).then(async (token) => {
            let formState = { ...that.formData, token };
            axios
              .post('/maxus/send.php', formState)
              .then(function (response) {
                that.message = true;
    
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                'event': 'form_submit'
                });
    
                that.msg = "Dziękujemy za wypełnienie formularza. <br/> Nasz pracownik skontaktuje się z Państwem.";
              })
              .catch(function () {
                window.alert("Błąd wysyłania zgłoszenia. Sprawdź swoje połączenie z internetem");
              });
          });
        });
      }
    }
  }
}
</script>