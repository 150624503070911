<template>
  <nav class="nav">
    <div class="nav__content">
      <div><img src="../assets/images/logo/maxus-logo.png" alt="maxus"></div>
      <div>
        <a href="/">
          <img src="../assets/images/logo/gkev.png" alt="Grupa krotoski EV">
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.nav{
  // position: absolute;
  // top:0px;
  // right: 0px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 20px #00000029;
  // border-radius: 0px 0px 0px 20px;
  max-width: 100%;
  width: 100%;
  padding:25px 7.292vw 17px 7.292vw;
  z-index: 2;

  @media(max-width:960px){
    position: relative;
    top:auto;
    right: auto;
    max-width: 100%;
    padding:24px 50px 17px 50px;
    border-radius: 0px;
  }
  @media(max-width:600px){
    padding:24px 30px 17px 30px;
  }
  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:first-of-type{
      margin-right: 20px;
    }
  }
}
</style>
