<template>
  <div>
    <div class="models__header text-center">
      <h3>MODELE MAXUS</h3>
    </div>
    <div class="models" id="offer">
      <div class="models__elem" v-for="(item, id) in cars" :key="id">
        <div class="models__elem-image">
          <img :src="require('@/assets/images/models/'+item.image)" :alt="item.header">
        </div>
        <div class="models__elem-content">
          <h3 v-html="item.header"></h3>
          <div class="models__elem-content--flex" v-if="item.range">
            <div class="models__elem-content--item">
              <img src="../assets/images/zasieg.png" alt="">
              <h4>Zasięg</h4>
              <p v-html="item.range"></p>
            </div>
            <div class="models__elem-content--item" v-if="item.battery">
              <img src="../assets/images/bateria.png" alt="">
              <h4>Bateria</h4>
              <p v-html="item.battery"></p>
            </div>
            <div class="models__elem-content--item" v-if="item.capacity">
              <img src="../assets/images/ladownosc.png" alt="">
              <h4>Ładowność</h4>
              <p v-html="item.capacity"></p>
            </div>
          </div>
          <div class="text-center">
            <a :href="item.catalog" target="_blank">
              <div class="models__elem-button">
              Pobierz katalog
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <a href="/maxus/download/Pricelist_Maxus_Polska-3.pdf" target="_blank">
        <div class="models__button">
          Pobierz cennik
        </div>
      </a>
    </div>

    <div class="text-center">
      <a href="https://oferta.krotoski.com/oferty/@/@/?brand_ids%5B%5D=1045&brand_ids%5B%5D=1044" target="_blank">
        <div class="models__button bg-big">
          Sprawdź dostępne samochody
          <!-- <img src="../assets/images/arrow.svg" alt=""> -->
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cars: [
        {
          'image': 'e-deliver-9.png',
          'header': 'e-Deliver 9',
          'range': '353 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '88,5 kWh',
          'capacity': '13 m³',
          'catalog': '/maxus/download/e-Deliver_9.pdf'
        },
        {
          'image': 'e-Deliver-3.jpg',
          'header': 'e-Deliver 3',
          'range': '371 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '50,2 kWh',
          'capacity': '6,3 m³',
          'catalog': '/maxus/download/e-Deliver_3.pdf'
        },
        {
          'image': 't90.jpg',
          'header': 'T90 EV Pickup',
          'range': '330 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '88,55 kWh',
          'capacity': '1000 kg',
          'catalog': '/maxus/download/T90_EV_Pickup.pdf'
        },
        {
          'image': 'Euniq-6.jpg',
          'header': 'Euniq 6',
          'range': '345-465 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '70 kWh',
          'capacity': '754 l',
          'catalog': '/maxus/download/Euniq_6.pdf'
        },
        {
          'image': 'euniq5.jpg',
          'header': 'Euniq 5 MPV',
          'range': '345-420 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '70 kWh',
          'capacity': '396 kg',
          'catalog': '/maxus/download/Euniq_5.pdf'
        },
        {
          'image': 'mifa9.jpg',
          'header': 'Maxus MIFA9',
          'range': '440-595 km <br> <span>(WLTP, cykl mieszany)</span>',
          'battery': '90 kWh',
          'catalog': '/maxus/download/Mifa_9.pdf'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.models{
  max-width: 1240px;
  width: 100%;
  margin: 50px auto 30px auto;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media(max-width: 1250px){
    max-width: 850px;
  }

  @media(max-width: 850px){
    max-width: 460px;
    padding:0 30px;
    margin: 50px auto 30px auto;
  }

  &__header{
    margin: 100px 30px 0 30px;
  }

  &__button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #848080;
    color: #ffffff;
    font-size: 24px;
    padding:36px 28px;
    border-radius: 20px;
    transition: all 0.6s;
    cursor: pointer;
    margin: 0 30px 30px 30px;

    @media(max-width: 960px){
      font-size: 20px;
      padding:30px 21px;
    }
    @media(max-width: 450px){
      font-size: 18px;
    }
    @media(max-width: 400px){
      font-size: 16px;
    }

    &:hover{
      background-color: #000000;
    }
    img{
      margin-left: 40px;
      @media(max-width:1440px){
        margin-left: 20px;
      }
    }
    &.bg-big{
      margin: 0 30px 100px 30px;
    }
  }

  &__elem{
    max-width: 368px;
    width: 100%;
    border:1px solid #54595F;
    padding-bottom: 30px;
    margin-bottom: 50px;

    @media(max-width: 460px){
      margin-bottom: 20px;
      padding-bottom: 0px;
    }

    &-button{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #848080;
      color: #ffffff;
      font-size: 20px;
      padding:23px 20px;
      border-radius: 20px;
      transition: all 0.6s;
      cursor: pointer;
      margin: 30px 30px 0px 30px;

      @media(max-width: 960px){
        font-size: 18px;
        padding:17px;
      }
      @media(max-width: 400px){
        font-size: 16px;
      }

      &:hover{
        background-color: #000000;
      }
    }

    &-image{
      text-align: center;
    }

    &-content{
      h3{
        font-size: 25px;
        font-weight: 700;
        margin:30px 0;
        text-align: center;
      }

      &--flex{
        display: flex;
        justify-content: center;

        @media(max-width: 460px){
          display: block;
        }
      }

      &--item{
        max-width: calc((100% / 3) - 40px);
        width: 100%;
        margin:0 20px;
        text-align: center;

        @media(max-width: 460px){
          max-width: 100%;
          margin:0 0px 20px 0px;
        }

        h4{
          font-size: 16px;
          font-weight: bold;
          margin: 10px 0;
        }
        p{
          font-size: 16px;
        }
        span{
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
